/* COLORS */
:root {
  /* --accent-primary: #0064ff;
  --accent-secondary: #b100e8;
  --object-primary: #00143c;
  --object-secondary: #80899d;
  --placeholder: #b9bec8;
  --border: #d9dfe7;
  --background: #eceff4;
  --highlight: #f6f8f9;
  --base: #fff;
  --success: #1bb560;
  --warning: #e59f02;
  --error: #cf3a1f;
  --dark-bg: #ffffff; */

  --accent-primary: #3282ff;
  --accent-secondary: #c91cff;
  --object-primary: #e8e8e8;
  --object-secondary: #99a1b1;
  --placeholder: #475673;
  --border: #293a5b;
  --background: #00143c;
  --highlight: #14274c;
  --base: #0a1d44;
  --success: #1ec96b;
  --warning: #ffb102;
  --error: #e64022;
  --dark-bg: #ffffff;
}
